<template>
    <div v-if="loading == false">
        <ChirpReport
            :data_source="rows"
            :export_report="export_report"
            :column_meta="headers"
            :preFormattedData="true"
            @rowSelected="handleRowClicked"
            :loadingColumns="loadingTable"
            ignoreDateConversion
            :storeKey="storeKey"
            :useOverride="useOverride"
            :totalCount="totalCount"
            :hide_speed_date="false"
            @cancelOverride="useOverride = false"
        />
    </div>
</template>
<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'reports';
    const report = 'invoice_status';
    import ChirpReport from '@/components/general/list/ChirpReport';
    import dayjs from '@/util/dayjs';
    export default {
        name: 'InvoiceStatus',
        components: { ChirpReport },
        props: {
            overrideFilter: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                rows: [],
                headers: [
                    { field: 'dayt_create', headerText: 'Invoice Date', allowSorting: true },
                    { field: 'id', headerText: 'Invoice #', allowSorting: true },
                    { field: 'client', headerText: 'Client', allowSorting: true },
                    { field: 'primary_counselor', headerText: 'Primary Clinician', allowSorting: true },
                    { field: 'amount_paid', headerText: 'Amount Paid', allowSorting: true, align: 'Right' },
                    { field: 'amount_owed', headerText: 'Amount Owed', allowSorting: true, align: 'Right' },
                    { field: 'status', headerText: 'Status', allowSorting: false, align: 'Right' },
                ],
                loading: true,
                loadingTable: false,
                useOverride: false,
                totalCount: 0,
                criteriaProps: {},
                export_report: 'invoice_status'
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'invoiceStatus';
            },
        },
        methods: {
            async load_data(criteria = this.criteria) {
                this.criteriaProps.report = report;
                const body = {
                    criteria: {
                        ...criteria,
                        report,
                    },
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows.map((row) => ({
                    dayt_create: dayjs(row.dayt_create).format('MM/DD/YYYY hh:mm A'),
                    id: row.id,
                    client: row.client,
                    primary_counselor: row.primary_counselor || '--',
                    amount_paid: row.amount_paid ? this.$getCurrency(row.amount_paid) : this.$getCurrency(0),
                    amount_owed: row.amount_owed ? this.$getCurrency(row.amount_owed) : this.$getCurrency(0),
                    status: row.amount_owed == 0 ? 'Paid' : 'Open',
                }));
                this.totalCount = res.data.total_count;

                if (
                    !criteria?.default_date_range ||
                    criteria?.default_date_range !== res.data.default_date_range
                ) {
                    this.$store.commit('filters/updateFilter', {
                        stateKey: this.storeKey,
                        criteria: {
                            ...this.criteria,
                            default_date_range: res.data.default_date_range,
                        },
                    });
                }

                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter,
                    });
                }

                if (!this.criteria.sort || (this.criteria.sort && Object.keys(this.criteria.sort).length === 0)) {
                    this.$store.commit('filters/applyDefaultDateSort', {
                        stateKey: this.storeKey,
                        customDateKey: 'dayt_create',
                    });
                }

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria,
                    });
                }
                this.criteriaProps = criteria;
                await this.load_data();
            },
            async handleRowClicked(data) {
                await this.$router.push({ name: 'Invoice', params: { invoice_id: data.id } });
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler(newVal, oldVal) {
                    let updatedNewVal = { ...newVal };

                    if (Object.keys(newVal.sort).length === 0){
                        updatedNewVal.sort = oldVal.sort;
                    }

                    const newStr = JSON.stringify(updatedNewVal);
                    const oldStr = JSON.stringify(oldVal);
                    if (newStr !== oldStr) {
                        await this.load_data(updatedNewVal);
                    }
                },
            },
        },
    };
</script>
